import { View, StyleSheet, Linking, Platform } from "react-native";
import React, { useState } from "react";
import {
  Box,
  Button,
  HStack,
  Icon,
  Image,
  Input,
  KeyboardAvoidingView,
  Stack,
  Text,
} from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { useTransporte } from "../queries/useTransporte";

export default function TransporteNativeScreen() {
  const handleWhatsAppPress = async () => {
    Linking.openURL(
      "https://api.whatsapp.com/send?phone=5493794341768&text=Hola "
    );
  };
  const [dominio, setDominio] = useState("");
  const { handleFetchTranporte, data, hasError, isLoading, error, fetch } =
    useTransporte();
  const [formError, setFormError] = useState<null | string>(null);

  const handleChangeInput = (domino: string) =>
    setDominio(domino.toUpperCase());

  const handleSubmit = async () => {
    setFormError(null);
    if (dominio.length <= 4) {
      setFormError("Ingresar dominio valido");
      return;
    }
    await handleFetchTranporte(dominio);
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
    >
      <Stack bg="white">
        <Text color="#004C35" fontSize="md" ml={4}>
          Consultá la habilitacion del transporte.
        </Text>
      </Stack>

      <View style={styles.wrapper}>
        {!fetch ? (
          <Box>
            <Box
              shadow={"2"}
              maxW="80"
              rounded="lg"
              overflow="hidden"
              borderColor="coolGray.200"
              borderWidth="1"
              _light={{
                backgroundColor: "white",
              }}
              pb={2}
              pt={8}
            >
              <Box>
                <Image
                  resizeMode="contain"
                  key={1}
                  h={110}
                  source={require("../../../../assets/transporte_seguro.png")}
                  alt="image"
                />
              </Box>
              <Stack p="4">
                <Text color="gray.500" fontWeight="300" fontSize={"sm"}>
                  DOMINIO
                </Text>
                <Input
                  size="lg"
                  InputLeftElement={
                    <Icon
                      as={<MaterialIcons name="money" />}
                      size={8}
                      ml="2"
                      color="muted.700"
                    />
                  }
                  placeholder="INGRESE DOMINIO"
                  value={dominio}
                  onChangeText={handleChangeInput}
                  maxLength={8}
                  isReadOnly={isLoading}
                />

                <Text color="red.400" fontWeight="300" fontSize={"sm"} mb={0}>
                  {formError && formError}
                </Text>

                <Button
                  onPress={handleSubmit}
                  size={"md"}
                  disabled={isLoading}
                  mt={0}
                  isLoading={isLoading}
                  isLoadingText=""
                >
                  Buscar
                </Button>

                <HStack alignItems="center" justifyContent="center">
                  <Text color="coolGray.400" fontWeight="400" mt={2}>
                    Nos preocupamos por tu seguridad
                  </Text>
                </HStack>
              </Stack>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box
              pt={4}
              shadow={"2"}
              maxW="80"
              minH={"96"}
              rounded="lg"
              overflow="hidden"
              borderColor="coolGray.200"
              justifyContent={"center"}
              borderWidth="1"
              _light={{
                backgroundColor: !hasError ? "green.50" : "red.50",
              }}
            >
              <Box
                style={{
                  padding: 10,
                  paddingVertical: 10,
                }}
              >
                {!hasError ? (
                  <Image
                    resizeMode="contain"
                    key={2}
                    h={110}
                    source={require("../../../../assets/ts_check.png")}
                    alt="image"
                  />
                ) : (
                  <Image
                    resizeMode="contain"
                    key={3}
                    h={110}
                    source={require("../../../../assets/ts_negativo.png")}
                    alt="image"
                  />
                )}

                <Stack fontSize="lg" mt={5} alignItems={"center"}>
                  <Text
                    width={"120"}
                    borderRadius={5}
                    py={1}
                    opacity={0.8}
                    fontSize="sm"
                    fontWeight={"bold"}
                    textAlign={"center"}
                    color={"white"}
                    bg={hasError ? "red.600" : "green.600"}
                  >
                    {hasError ? " NO HABILITADO " : "HABILITADO"}
                  </Text>
                </Stack>
              </Box>
              <Stack p="4" space={3}>
                {!hasError ? (
                  <Stack>
                    <Text fontSize="lg" bold>
                      Patente:
                      <Text fontSize="lg" fontWeight={"normal"}>
                        {" "}
                        {data?.dominio}
                      </Text>
                    </Text>
                    <Text fontSize="lg" bold>
                      Habilitacion:
                      <Text fontSize="lg" fontWeight={"normal"}>
                        {" "}
                        {data?.habilitacion}
                      </Text>
                    </Text>
                    <Text fontSize="lg" bold>
                      Empresa:
                      <Text fontSize="lg" fontWeight={"normal"}>
                        {" "}
                        {data?.empresa}
                      </Text>
                    </Text>
                    <Text fontSize="lg" bold>
                      Titular:
                      <Text fontSize="lg" fontWeight={"normal"}>
                        {" "}
                        {data?.titular}
                      </Text>
                    </Text>
                    <Text fontSize="lg" bold>
                      Chofer/es:
                      <Text fontSize="lg" fontWeight={"normal"}>
                        {" "}
                        {data?.choferes.map(
                          (c: any, i: number) => `${i + 1}-${c.apyn_ch}  `
                        )}
                      </Text>
                    </Text>
                  </Stack>
                ) : (
                  <Text
                    fontSize="lg"
                    fontWeight={"normal"}
                    textAlign={"center"}
                  >
                    {error.code != 500
                      ? `ESTE VEHICULO "${dominio}" NO SE ENCUENTRA HABILITADO`
                      : `ERROR INTERNO DEL SERVIDOR`}
                  </Text>
                )}

                <Button onPress={handleWhatsAppPress} size={"md"} mt={3}>
                  Realizar Reclamo
                </Button>

                <HStack alignItems="center" justifyContent="center">
                  <Text color="coolGray.400" fontWeight="400">
                    Nos preocupamos por tu seguridad
                  </Text>
                </HStack>
              </Stack>
            </Box>
          </Box>
        )}
      </View>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: "white",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
