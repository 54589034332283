import {
  Box,
  Text,
  View,
  Flex,
  Pressable,
  Image,
  useBreakpointValue,
} from "native-base";

import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { RootStackParamList } from "../../../navigator";
import { HomeCardInfo } from "../screens/home.screen";
import React from 'react'

export function HomeCards({ webCardInfo }: any) {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const dimensions = useBreakpointValue({
    base: 300,
    lg: 250,
    xl: 250,
    "2xl": 250,
  });

  return (
    <>
      {webCardInfo.map((cardInfo: HomeCardInfo, index: number) => (
        <Pressable
          onPress={() => navigation.navigate(cardInfo.path)}
          key={index}
        >
          <Flex
            h={dimensions}
            w={dimensions}
            bg="white"
            rounded="md"
            m={6}
            shadow={8}
            direction="column"
            align="center"
            justify="space-around"
          >
            <Box>
              <Text
                fontSize={{ base: "2xl", lg: "lg", xl: "xl" }}
                fontWeight="bold"
                color="#004c35"
                mt={6}
              >
                {cardInfo.titulo}
              </Text>
            </Box>
            <View>
              <Image
                mt={"3"}
                source={cardInfo.imagen}
                alt="route card"
                w={100}
                h={100}
                alignItems="center"
                justifyContent="center"
              />
            </View>
            <Text
              width={"full"}
              mt={"4"}
              minH={100}
              textAlign="center"
              fontWeight="bold"
              color="#004c35"
              paddingX={"1"}
            >
              {cardInfo.descripcion}
            </Text>
          </Flex>
        </Pressable>
      ))}
    </>
  );
}
